import "./styles/app.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Alert from "react-s-alert";
import AuthDataProvider from "./components/common/AuthDataProvider";
import MainComponent from "./components/MainComponent";

const Lottery = props => {
    return (
        <BrowserRouter>
            <AuthDataProvider>
                <MainComponent />
                <Alert stack={{ limit: 3 }} timeout={3000} position="top-right" effect="slide" offset={65} />
            </AuthDataProvider>
        </BrowserRouter>
    )
}

export default Lottery;